import React, { Component, Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

/** Real stuff **/
const Exam = lazy(() => import('./exam/Exam'));

/** Demo stuff **/
const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const RtlLayout = lazy(() => import('./layout/RtlLayout'));

const Widgets = lazy(() => import('./widgets/Widgets'));

const KanbanBoard = lazy(() => import('./apps/KanbanBoard'));
const Tickets = lazy(() => import('./apps/Tickets'));
const Chats = lazy(() => import('./apps/Chats'));
const TodoList = lazy(() => import('./apps/TodoList'));
const TodoListRtl = lazy(() => import('./apps/TodoListRtl'));

const Accordions = lazy(() => import('./basic-ui/Accordions'));
const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Badges = lazy(() => import('./basic-ui/Badges'));
const Breadcrumbs = lazy(() => import('./basic-ui/Breadcrumbs'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Modals = lazy(() => import('./basic-ui/Modals'));
const Progress = lazy(() => import('./basic-ui/Progress'));
const Paginations = lazy(() => import('./basic-ui/Paginations'));
const TabsPage = lazy(() => import('./basic-ui/TabsPage'));
const Typography = lazy(() => import('./basic-ui/Typography'));
const Tooltips = lazy(() => import('./basic-ui/Tooltips'));
const Popups = lazy(() => import('./basic-ui/Popups'));

const Dragula = lazy(() => import('./advanced-ui/Dragula'));
const Clipboard = lazy(() => import('./advanced-ui/Clipboards'));
const ContextMenu = lazy(() => import('./advanced-ui/ContextMenus'));
const Sliders = lazy(() => import('./advanced-ui/Sliders'));
const Carousel = lazy(() => import('./advanced-ui/Carousel'));
const Loaders = lazy(() => import('./advanced-ui/Loaders'));
const TreeView = lazy(() => import('./advanced-ui/TreeView'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));
const AdvancedElements = lazy(() => import('./form-elements/AdvancedElements'));
const Validation = lazy(() => import('./form-elements/Validation'));
const Wizard = lazy(() => import('./form-elements/Wizard'));

const BasicTable = lazy(() => import('./tables/BasicTable'));
const DataTable = lazy(() => import('./tables/DataTables'));
const ReactTable = lazy(() => import('./tables/ReactTable'));
const SortableTable = lazy(() => import('./tables/SortableTable'));

const VectorMap = lazy(() => import('./maps/VectorMap'));
const SimpleMap = lazy(() => import('./maps/SimpleMap'));

const Notifications = lazy(() => import('./notifications/Notifications'));

const Mdi = lazy(() => import('./icons/Mdi'));
const FlagIcons = lazy(() => import('./icons/FlagIcons'));
const FontAwesome = lazy(() => import('./icons/FontAwesome'));
const SimpleLine = lazy(() => import('./icons/SimpleLine'));
const Themify = lazy(() => import('./icons/Themify'));
const TypIcons = lazy(() => import('./icons/TypIcons'));

const TextEditors = lazy(() => import('./editors/TextEditors'));
const CodeEditor = lazy(() => import('./editors/CodeEditor'));

const ChartJs = lazy(() => import('./charts/ChartJs'));
const C3Charts = lazy(() => import('./charts/C3Charts'));
const Chartist = lazy(() => import('./charts/Chartist'));
const GoogleCharts = lazy(() => import('./charts/GoogleCharts'));
const SparkLineCharts = lazy(() => import('./charts/SparkLineCharts'));
const GuageChart = lazy(() => import('./charts/GuageChart'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Login2 = lazy(() => import('./user-pages/Login2'));
const Register1 = lazy(() => import('./user-pages/Register'));
const Register2 = lazy(() => import('./user-pages/Register2'));
const Lockscreen = lazy(() => import('./user-pages/Lockscreen'));

const LandingPage = lazy(() => import('./general-pages/LandingPage'));
const BlankPage = lazy(() => import('./general-pages/BlankPage'));
const Profile = lazy(() => import('./general-pages/Profile'));
const Faq = lazy(() => import('./general-pages/Faq'));
const Faq2 = lazy(() => import('./general-pages/Faq2'));
const NewsGrid = lazy(() => import('./general-pages/NewsGrid'));
const Timeline = lazy(() => import('./general-pages/Timeline'));
const SearchResults = lazy(() => import('./general-pages/SearchResults'));
const Portfolio = lazy(() => import('./general-pages/Portfolio'));
const UserListing = lazy(() => import('./general-pages/UserListing'));

const Invoice = lazy(() => import('./ecommerce/Invoice'));
const Pricing = lazy(() => import('./ecommerce/Pricing'));
const ProductCatalogue = lazy(() => import('./ecommerce/ProductCatalogue'));
const ProjectList = lazy(() => import('./ecommerce/ProjectList'));
const Orders = lazy(() => import('./ecommerce/Orders'));

const Email = lazy(() => import('./apps/Email'));
const Calendar = lazy(() => import('./apps/Calendar'));
const Gallery = lazy(() => import('./apps/Gallery'));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route exact path="/dashboard" element={<Dashboard />} />

          <Route exact path="/layout/RtlLayout" element={<RtlLayout />} />

          <Route exact path="/widgets" element={<Widgets />} />

          <Route exact path="/apps/kanban-board" element={<KanbanBoard />} />
          <Route exact path="/apps/todo-list" element={<TodoList />} />
          <Route exact path="/apps/todo-list-rtl" element={<TodoListRtl />} />
          <Route exact path="/apps/tickets" element={<Tickets />} />
          <Route exact path="/apps/chats" element={<Chats />} />

          <Route path="/basic-ui/accordions" element={<Accordions />} />
          <Route path="/basic-ui/buttons" element={<Buttons />} />
          <Route path="/basic-ui/badges" element={<Badges />} />
          <Route path="/basic-ui/breadcrumbs" element={<Breadcrumbs />} />
          <Route path="/basic-ui/dropdowns" element={<Dropdowns />} />
          <Route path="/basic-ui/modals" element={<Modals />} />
          <Route path="/basic-ui/progressbar" element={<Progress />} />
          <Route path="/basic-ui/pagination" element={<Paginations />} />
          <Route path="/basic-ui/tabs" element={<TabsPage />} />
          <Route path="/basic-ui/typography" element={<Typography />} />
          <Route path="/basic-ui/tooltips" element={<Tooltips />} />
          <Route path="/basic-ui/popups" element={<Popups />} />

          <Route path="/advanced-ui/dragula" element={<Dragula />} />
          <Route path="/advanced-ui/clipboard" element={<Clipboard />} />
          <Route path="/advanced-ui/context-menu" element={<ContextMenu />} />
          <Route path="/advanced-ui/sliders" element={<Sliders />} />
          <Route path="/advanced-ui/carousel" element={<Carousel />} />
          <Route path="/advanced-ui/loaders" element={<Loaders />} />
          <Route path="/advanced-ui/tree-view" element={<TreeView />} />

          <Route
            path="/form-Elements/basic-elements"
            element={<BasicElements />}
          />
          <Route
            path="/form-Elements/advanced-elements"
            element={<AdvancedElements />}
          />
          <Route path="/form-Elements/validation" element={<Validation />} />
          <Route path="/form-Elements/wizard" element={<Wizard />} />

          <Route path="/tables/basic-table" element={<BasicTable />} />
          <Route path="/tables/data-table" element={<DataTable />} />
          <Route path="/tables/react-table" element={<ReactTable />} />
          <Route path="/tables/sortable-table" element={<SortableTable />} />

          <Route path="/maps/vector-map" element={<VectorMap />} />
          <Route path="/maps/simple-map" element={<SimpleMap />} />

          <Route path="/notifications" element={<Notifications />} />

          <Route path="/icons/mdi" element={<Mdi />} />
          <Route path="/icons/flag-icons" element={<FlagIcons />} />
          <Route path="/icons/font-awesome" element={<FontAwesome />} />
          <Route path="/icons/simple-line" element={<SimpleLine />} />
          <Route path="/icons/themify" element={<Themify />} />
          <Route path="/icons/typicons" element={<TypIcons />} />

          <Route path="/editors/text-editors" element={<TextEditors />} />
          <Route path="/editors/code-editor" element={<CodeEditor />} />

          <Route path="/icons/themify" element={<Themify />} />

          <Route path="/charts/chart-js" element={<ChartJs />} />
          <Route path="/charts/c3-chart" element={<C3Charts />} />
          <Route path="/charts/chartist" element={<Chartist />} />
          <Route path="/charts/google-charts" element={<GoogleCharts />} />
          <Route
            path="/charts/sparkline-charts"
            element={<SparkLineCharts />}
          />
          <Route path="/charts/guage-chart" element={<GuageChart />} />

          <Route path="/user-pages/login-1" element={<Login />} />
          <Route path="/user-pages/login-2" element={<Login2 />} />
          <Route path="/user-pages/register-1" element={<Register1 />} />
          <Route path="/user-pages/register-2" element={<Register2 />} />
          <Route path="/user-pages/lockscreen" element={<Lockscreen />} />

          <Route path="/error-pages/error-404" element={<Error404 />} />
          <Route path="/error-pages/error-500" element={<Error500 />} />

          <Route path="/general-pages/blank-page" element={<BlankPage />} />
          <Route path="/general-pages/landing-page" element={<LandingPage />} />
          <Route path="/general-pages/profile" element={<Profile />} />
          <Route path="/general-pages/faq-1" element={<Faq />} />
          <Route path="/general-pages/faq-2" element={<Faq2 />} />
          <Route path="/general-pages/news-grid" element={<NewsGrid />} />
          <Route path="/general-pages/timeline" element={<Timeline />} />
          <Route
            path="/general-pages/search-results"
            element={<SearchResults />}
          />
          <Route path="/general-pages/portfolio" element={<Portfolio />} />
          <Route path="/general-pages/user-listing" element={<UserListing />} />

          <Route path="/ecommerce/invoice" element={<Invoice />} />
          <Route path="/ecommerce/pricing" element={<Pricing />} />
          <Route
            path="/ecommerce/product-catalogue"
            element={<ProductCatalogue />}
          />
          <Route path="/ecommerce/project-list" element={<ProjectList />} />
          <Route path="/ecommerce/orders" element={<Orders />} />

          <Route path="/apps/email" element={<Email />} />
          <Route path="/apps/calendar" element={<Calendar />} />
          <Route path="/apps/gallery" element={<Gallery />} />

          <Route path="/apps/exam" element={<Exam />} />

          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Suspense>
    );
  }
}

export default AppRoutes;
