import React, { Component } from 'react';
import i18n from 'i18next';
import { withRouter } from '../with-router';
import './App.scss';
import AppRoutes from './AppRoutes';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { withTranslation } from 'react-i18next';

class App extends Component {
  state = {};

  componentDidMount() {
    this.onRouteChanged();
    i18n.changeLanguage();
  }

  render() {
    const footerProps = {};
    const headerProps = {};

    if (this.state.isFocusedLayout && !this.state.isFullPageLayout) {
      headerProps.minimalist = true;
      footerProps.minimalist = true;
    }

    return (
      <div className="container-scroller">
        {!this.state.isFullPageLayout && <Header {...headerProps} />}
        <div className={`container-fluid ${this.state.extraClassName}`}>
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes />
            </div>
            {!this.state.isFullPageLayout && <Footer {...headerProps} />}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    // eslint-disable-next-line no-console
    const { i18n } = this.props;
    const body = document.querySelector('body');
    const pathname = this.props.location && this.props.location.pathname;

    if (pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    } else {
      body.classList.remove('rtl');
      i18n.changeLanguage();
    }
    window.scrollTo(0, 0);

    const focusedLayoutRoutes = ['/apps/exam'];

    const fullPageLayoutRoutes = [
      '/user-pages/login-1',
      '/user-pages/login-2',
      '/user-pages/register-1',
      '/user-pages/register-2',
      '/user-pages/lockscreen',
      '/error-pages/error-404',
      '/error-pages/error-500',
      '/general-pages/landing-page',
    ];

    const isFullPageLayout = !!fullPageLayoutRoutes.find((x) => x === pathname);
    const isFocusedLayout =
      !!focusedLayoutRoutes.find((x) => x === pathname) || isFullPageLayout;
    const extraClassName = isFullPageLayout
      ? 'full-page-wrapper'
      : 'page-body-wrapper';

    this.setState({
      extraClassName,
      isFocusedLayout,
      isFullPageLayout,
    });
  }
}

export default withTranslation()(withRouter(App));
