import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import App from './app/App';
import './i18n';
import * as serviceWorker from './serviceWorker';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <App tab="home" />
  </BrowserRouter>,
);

serviceWorker.unregister();
